import WithSubnavigation from "../components/NavBar";
import AppFooter from "../components/AppFooter";
import { Link } from "react-router-dom";
function Certifications() {
    return (
      <>
      <WithSubnavigation />
       <div className="container">
        <h1>Azure Certifications</h1>
        <div style={{marginBottom: 20}} className="card">
          <div className="card-header">
            <h3>Microsoft Certified: DevOps Engineer Expert</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://learn.microsoft.com/en-us/media/learn/certification/badges/microsoft-certified-expert-badge.svg"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Design and implement processes and communications</li>
                          <li>Design and implement a source control strategy</li>
                          <li>Design and implement build and release pipelines</li>
                          <li>Develop a security and compliance plan</li>
                          <li>Implement an instrumentation strategy</li>
                         
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://learn.microsoft.com/en-us/users/haroldasvaranauskas-8375/credentials/abe6aef50ad9515d?ref=https%3A%2F%2Fwww.linkedin.com%2F">Learn More</Link>
                </div> 
          </div>
        </div>
        <div style={{marginBottom: 20}} className="card">
          <div className="card-header">
            <h3>Microsoft Certified: Azure Developer Associate</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://learn.microsoft.com/en-us/media/learn/certification/badges/microsoft-certified-associate-badge.svg"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Develop Azure compute solutions</li>
                          <li>Develop for Azure storage</li>
                          <li>Implement Azure security</li>
                          <li>Monitor, troubleshoot, and optimize Azure solutions</li>
                          <li>Connect to and consume Azure services and third-party services</li>
                         
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://www.credly.com/badges/b65fed5e-774f-48d0-938e-cfb0e877680b/linked_in_profile">Learn More</Link>
                </div> 
          </div>
        </div>
        <div style={{marginBottom: 20}} className="card">
          <div className="card-header">
            <h3>Microsoft Certified: Dynamics 365 Fundamentals (ERP)</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://images.credly.com/images/f611a90e-b8e2-4fdf-9bd6-9e8d5f4c30e8/dynamics365-fundamentals-erp-600x600.png"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Dynamics 365</li>
                          <li>Enterprise Software</li>
                          <li>Professional Services</li>
                         
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://www.credly.com/badges/b65fed5e-774f-48d0-938e-cfb0e877680b/linked_in_profile">Learn More</Link>
                </div> 
          </div>
        </div>
        <div style={{marginBottom: 20}} className="card">
          <div className="card-header">
            <h3>Microsoft Certified: Dynamics 365 Fundamentals (CRM)</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://images.credly.com/images/42992295-0ee2-4527-982d-e51efbec40fc/dynamics365-fundamentals-crm-600x600.png"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Dynamics 365</li>
                          <li>Enterprise Software</li>
                          <li>Professional Services</li>
                         
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://www.credly.com/badges/b65fed5e-774f-48d0-938e-cfb0e877680b/linked_in_profile">Learn More</Link>
                </div> 
          </div>
        </div>
        <div style={{marginBottom: 20}} className="card ">
          <div className="card-header">
            <h3>Microsoft Certified: Azure AI Fundamentals</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://images.credly.com/size/340x340/images/4136ced8-75d5-4afb-8677-40b6236e2672/azure-ai-fundamentals-600x600.png"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Azure Bot Services</li>
                          <li>Azure Machine Learning</li>
                          <li>Cognitive Services</li>
                         
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://www.credly.com/badges/ac0ed96a-4088-4f9b-a611-49e87815060c/linked_in_profile">Learn More</Link>
                </div> 
          </div>
        </div>
        <div style={{marginBottom: 20}} className="card ">
          <div className="card-header">
            <h3>Microsoft Certified: Azure Fundamentals</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://images.credly.com/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/image.png"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Azure</li>
                          <li>Cloud Data</li>
                          <li>Cloud Networking</li>
                          <li>Cloud Security</li>
                          <li>Cloud Services</li>
                          <li>Cloud Storage</li>
                          <li>Visualization</li>
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://www.credly.com/badges/e15b8049-caff-469f-a871-1acb80481540">Learn More</Link>
                </div> 
          </div>
        </div>
        <h1>AWS Certifications</h1>
        <div style={{marginBottom: 20}} className="card">
          <div className="card-header">
            <h3>AWS Certified Cloud Practitioner</h3>
          </div>
          <div className="card-body">
            <div className="d-flex">

         
              <img style={{height: 200}} src="https://images.credly.com/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png"></img>
              <div>
                  <h4>Skills</h4>  
                      <ul>
                          <li>Amazon Web Services</li>
                          <li>AWS</li>
                          <li>Cloud Computing</li>
                          <li>Cloud Security</li>
                          <li>Cloud Services</li>
                          <li>Cloud Storage</li>
                      </ul>
              </div>
            
              </div>   
              <div className="card-footer">
                <Link style={{padding: 10, borderRadius: 5}} className="bg-success bg-gradient bg-opacity-80" to="https://www.credly.com/badges/ce2bcdce-1124-4e62-a25d-68394c5e8f8c/public_url">Learn More</Link>
                </div> 
          </div>
        </div>
        
      </div>
      <AppFooter />
      </>
     
    );
  }   
  
  export default Certifications;    