import AppFooter from "../components/AppFooter";
import WithSubnavigation from "../components/NavBar";

function Cookies () {
  return (
    <>
    <WithSubnavigation />
    <div className="container">
      <h1>Cookie Policy</h1>
      <p>Last updated: 2024-06-01</p>
      <h2>1. Introduction</h2>
        <p>Welcome to haroldasvaranauskas.com. We are committed to protecting and respecting your privacy. This Cookie Policy explains what cookies are, how we use them, the types of cookies we use, and your choices regarding cookies when you visit our website.</p>
    <h2>2. What are cookies?</h2>
        <p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.</p>
        <p>Cookies can be "persistent" or "session" cookies.</p>
    <h2>3. How we use cookies?</h2>
        <ul>
            <li>When you use and access the Service, we may place a number of cookies files in your web browser.</li>
            <li>We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, to enable advertisements delivery, including behavioral advertising.</li>
            <li>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</li>
            <li>Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.</li>
            <li>Third-party cookies. In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</li>
        </ul>
    <h2>4. What are your choices regarding cookies?</h2>
    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by adjusting the settings on your browser. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of our website or other websites you visit.</p>
    <h2>5 How to manage Cookies?</h2>
    <ul>
        <li>
      <b> Browser Settings:</b>  Most browsers allow you to change your cookie settings. These settings will typically be found in the "options" or "preferences" menu of your browser.

        </li>
        <li>
     <b> Opt-Out Tools:</b>   You can opt out of interest-based advertising through certain tools provided by third-party organizations such as the Digital Advertising Alliance (DAA), the Network Advertising Initiative (NAI), and the European Interactive Digital Advertising Alliance (EDAA).
        </li>
    </ul>
    <h2>6. Changes to This Cookie Policy</h2>
    <p>We may update this Cookie Policy from time to time in order to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
    <h2>7. Important Notice</h2>  
    <p>
    By continuing to use our website without changing your settings, you consent to our use of cookies and other tracking technologies as described in this Cookie Policy.
    </p>
    <h1>
    GDPR Privacy Policy

    </h1>
    <h2>1. Introduction</h2>
    <p>
     We respect your privacy and are committed to protecting your personal data. This GDPR Privacy Policy explains how we handle your personal data when you visit our website, haroldasvaranauskas.com (the "Website"). Please note that we do not collect any personal data from users. We only use cookies to improve the performance of our application.
    </p>
    <h2>2. What data do we collect?</h2>
    <h3>Personal data</h3>
    <p>We do not collect any personal data from users.</p>
    <h3>Usage data</h3>
    <p>We may collect information about how you use our Website, such as the pages you visit and the links you click on. This information helps us improve the performance of our Website.</p>
    <h2>Your Rights</h2>
    <p>Under the General Data Protection Regulation (GDPR), you have several rights regarding your personal data, even though we do not collect any. These rights include:</p>
    <ul>
        <li>The right to access your personal data.</li>
        <li>The right to rectify your personal data if it is inaccurate or incomplete.</li>
        <li>The right to erase your personal data.</li>
        <li>The right to restrict the processing of your personal data.</li>
        <li>The right to object to the processing of your personal data.</li>
        <li>The right to data portability.</li>
        <li>The right to lodge a complaint with a supervisory authority.</li>
    </ul>
    <h2>Contact Us</h2>
    <p>If you have any questions about this GDPR Privacy Policy, please contact us using the contact form provided on our Website.</p>
    </div>
    <AppFooter />
    </>
  )
}

export default Cookies;