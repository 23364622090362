import WithSubnavigation from "../components/NavBar";
import AppFooter from "../components/AppFooter";

function Posts() {
    return (
      <>
      <WithSubnavigation />
      <div className='container vh-100'>
        <h1>Comming Soon...</h1>
      </div>
      <AppFooter />
      </>
     
    );
  }   
  
  export default Posts;    